import { onDomReady } from 'cantil';
import { io } from 'socket.io-client';
import 'bootstrap/dist/css/bootstrap.min.css';
import api from './api';

const configs = {
  requestTime: process.env.REQUEST_TIME_MILISECONDS ?? 2000,
  backendUrl: process.env.BACKEND_URL,
  email: process.env.USER_EMAIL,
  password: process.env.USER_PASSWORD,
  url: process.env.WEBSOCKET_URL,
  room: undefined,
  id: process.env.CLIENT_NAME,
};

const bluetooth = {
  service: '3524cb3e-a174-11e9-a2a3-2a2ae2dbcce4',
  characteristic: '3524cda0-a174-11e9-a2a3-2a2ae2dbcce4',
  notifications: {
    acc: '3524d548-a174-11e9-a2a3-2a2ae2dbcce4',
    gsr: '3524d0d4-a174-11e9-a2a3-2a2ae2dbcce4',
    hr: '3524d408-a174-11e9-a2a3-2a2ae2dbcce4',
  },
};

// Define the previousData variable outside of the function so it can be accessed from sendData
let events = [];
let devicesArr = [];
// const authToken = '';
const connectedDevicesHashtable = new Map();
let pairedDevices = [];

function createDataObject() {
  const dataObj = {
    timestamp: 0,
    gsr: [],
    acc: [],
    hr: [],
    device: undefined,
  };

  return dataObj;
}
const processAccelerometer = arr => {
  if (!Array.isArray(arr) || !arr.length) return 0;
  const [x, y, z] = arr.map(val => {
    const complement = (val > 127) ? val - 256 : val;
    return (complement * 0.032) ** 2;
  });
  return Math.sqrt(x + y + z);
};

function checkAndSendData(data) {
  data.acc = processAccelerometer(data.acc);
  window.app.sendToServer(data);
}

function listEvents(eventsArr) {
  const select = document.getElementById('events');
  eventsArr.forEach((x, i) => {
    const el = document.createElement('option');
    el.setAttribute('id', i);
    el.textContent = `${x.label} - ${x.location} - ${x.code}`;
    el.value = x.code;
    select.appendChild(el);
  });
}

async function BluetoothDeviceToUnpair(device) {
  if (!device) {
    return window.app.log('No bluetooth device to unpair');
  }

  try {
    window.app.log(`Unpairing from Bluetooth Device ${device.name}`);
    await device.forget().then(
      () => window.app.removeTableRow('paired-devices', device.name)
    );
  } catch (error) {
    window.app.log(`Argh! ${error}`);
  }
}

async function listenForNotifications(service, type, notificationsUuid, dataObj) {
  const notifications = await service.getCharacteristic(notificationsUuid);

  // Subscribe to notifications
  await notifications.startNotifications();

  notifications.addEventListener('characteristicvaluechanged', event => {
    const { value } = event.target;
    // eslint-disable-next-line default-case
    switch (type) {
      case 'gsr':
        dataObj.gsr = [];
        for (let i = 2; i <= 18; i += 2) {
          dataObj.gsr.push((value.getUint8(i) << 8) | value.getUint8(i + 1));
        }
        break;

      case 'acc':
        dataObj.acc = [];
        dataObj.validity = value.getUint8(2);
        for (let i = 3; i <= 5; i += 1) {
          dataObj.acc.push(value.getInt8(i));
        }
        break;

      case 'hr':
        dataObj.hr = [];
        dataObj.validity = value.getUint8(2);
        dataObj.hr.push(value.getUint8(3));
        dataObj.hr.push((value.getUint8(4) << 8) | value.getUint8(5));
        break;
    }
    dataObj.timestamp = (value.getUint8(0) << 8) | value.getUint8(1);
  });
}

// eslint-disable-next-line no-unused-vars
async function connectToBluetoothDevice(device, flag) {
  const dataObj = createDataObject();

  if (connectedDevicesHashtable.has(device.name)) {
    window.app.log(`${device.name} is already connected`);
    return;
  }

  try {
    const server = await device.gatt.connect();
    window.app.log(`> Bluetooth device "${device.name} connected.`);
    connectedDevicesHashtable.set(device.name, device.name);

    if (flag === 'new-connection' && pairedDevices.indexOf(device.name) === -1) {
      window.app.addTableRow('paired-devices', device.name);
    }
    dataObj.device = device.name;

    const service = await server.getPrimaryService(bluetooth.service);
    const characteristic = await service.getCharacteristic(bluetooth.characteristic);

    // START ALL
    await characteristic.writeValueWithResponse(new Uint8Array([0x01, 0xAB]));

    // Get the characteristic you want to receive notifications from
    await listenForNotifications(service, 'gsr', bluetooth.notifications.gsr, dataObj);
    await listenForNotifications(service, 'hr', bluetooth.notifications.hr, dataObj);
    await listenForNotifications(service, 'acc', bluetooth.notifications.acc, dataObj);
  } catch (error) {
    window.app.log(`Error! ${error}`);
  }

  window.setInterval(() => checkAndSendData(dataObj), configs.requestTime);
}

async function connectToBluetoothDeviceFromAdvertisement(device, table) {
  const abortController = new AbortController();

  device.addEventListener('advertisementreceived', async () => {
    window.app.log(`> Received advertisement from "${device.name}"...`);
    // Stop watching advertisements to conserve battery life.
    abortController.abort();

    window.app.log(`Connecting to GATT Server from "${device.name}"...`);
    try {
      connectToBluetoothDevice(device);
      if (table === 'paired-devices') {
        window.app.changeCellTextColor('paired-devices', device.name, 'MediumSpringGreen');
      } else {
        window.app.changeCellTextColor('event-sensors', device.name, 'MediumSpringGreen');
      }
    } catch (error) {
      window.app.log(`Argh! ${error}`);
    }
  }, { once: true });
  device.addEventListener('gattserverdisconnected', async () => {
    window.app.log(`Lost connection to ${device.name}`);

    await connectToBluetoothDeviceFromAdvertisement(device);
  });
  try {
    window.app.log(`Watching advertisements from "${device.name}"...`);
    await device.watchAdvertisements({ signal: abortController.signal });
  } catch (error) {
    window.app.log(`Argh! ${error}`);
  }
}

window.app = {
  init: async () => {
    window.app.log('App initialized');
    window.app.login();
    window.app.startWakeLock();

    const devices = await navigator.bluetooth.getDevices();
    pairedDevices = devices.map(el => el.name);

    window.app.tableCreate(pairedDevices, 'paired-devices', 'cream');
  },

  login: async () => {
    // const response = await api.post('/login', {
    //   email: configs.email,
    //   password: configs.password,
    // });

    // authToken = response.data?.data.token;
    await window.app.getEvents();
  },

  startWakeLock: async () => {
    if ('wakeLock' in navigator) {
      try {
        // Request a wake lock
        await navigator.wakeLock.request('screen', { stayAwake: true });
      } catch (err) {
        // Handle errors, such as when the wake lock is not granted
        console.error(`${err.name}, ${err.message}`);
      }
    }
  },

  getEvents: async () => {
    const response = await api.get('/events');

    if (response.status !== 200) {
      events = [];
      return;
    }

    events = response.data;
    listEvents(events);
  },

  initSocket: async sensorsName => {
    window.socket = io.connect(configs.url, {
      pingInterval: 5000,
      pingTimeout: 30000,
    });

    // Login
    await window.socket.emit('guest', { id: configs.id, sensors: sensorsName });

    // Join
    await window.socket.emit('join', { room: configs.room });
  },

  sendToServer(data) {
    if (!window.socket) return;

    window.socket.emit('message', {
      room: configs.room,
      user: configs.id,
      message: data,
    });
  },

  onConnectToBluetoothDevicesButtonClick: async () => {
    try {
      window.app.log('Getting existing permitted Bluetooth devices...');
      const devices = await navigator.bluetooth.getDevices();

      window.app.log(`> Got ${devices.length} Bluetooth devices.`);
      // These devices may not be powered on or in range, so scan for
      // advertisement packets from them before connecting.
      devices.forEach(device => {
        connectToBluetoothDeviceFromAdvertisement(device, 'paired-devices');
      });
    } catch (error) {
      window.app.log(`Argh! ${error}`);
    }
  },

  requestBluetoothDevice: async () => {
    try {
      window.app.log('Requesting any Bluetooth device...');
      const device = await navigator.bluetooth.requestDevice({
        optionalServices: [bluetooth.service],
        filters: [{ namePrefix: 'MP_J4' }],
      });

      connectToBluetoothDevice(device, 'new-connection');

      window.app.log(`> Requested ${device.name}`);
    } catch (error) {
      window.app.log(`Error! ${error}`);
    }
  },

  log: message => {
    const pre = document.querySelector('pre');
    const p = document.createElement('p');
    p.innerText = message;
    p.classList.add('m-0');
    pre.append(p);

    if (pre.childElementCount > 30) {
      pre.firstChild.remove();
    }
  },

  tableCreate(devices, id, color) {
    const body = document.getElementById('table');
    const tbl = document.createElement('table');

    const tableTitle = id === 'event-sensors' ? 'This Device Event Sensors' : 'Paired Devices';

    tbl.setAttribute('id', id);
    tbl.classList.add('tableTitle', 'table-striped', 'table', 'table-dark');

    const tbdy = document.createElement('tbody');
    // Create an empty <thead> element and add it to the table:
    const header = tbl.createTHead();

    // Create an empty <tr> element and add it to the first position of <thead>:
    const row = header.insertRow(0);

    // Insert a new cell (<td>) at the first position of the "new" <tr> element:
    const cell = row.insertCell(0);
    cell.setAttribute('class', 'col-3');
    cell.setAttribute('scope', 'col');
    // Add some bold text in the new cell:
    cell.innerHTML = `<b>${tableTitle}</b>`;
    devices.forEach(device => {
      const tr = tbl.insertRow();
      const td = tr.insertCell();
      td.setAttribute('id', `${id}-${device}`);
      td.style.color = color;
      td.setAttribute('class', 'col-3');
      td.setAttribute('scope', 'col');
      td.appendChild(document.createTextNode(device));
      tr.appendChild(td);
      tbdy.appendChild(tr);
      tbl.appendChild(tbdy);
    });
    body.appendChild(tbl);
  },

  changeCellTextColor(tableId, id, color) {
    document.getElementById(`${tableId}-${id}`).style.color = color;
  },

  addTableRow(tableId, device) {
    const tbl = document.getElementById(tableId);
    const tr = tbl.insertRow();
    const td = tr.insertCell();
    td.setAttribute('id', `${tableId}-${device}`);
    td.appendChild(document.createTextNode(device));
    tr.appendChild(td);
    tbl.appendChild(tr);
  },

  removeTableRow(tableId, device) {
    const td = document.getElementById(`${tableId}-${device}`);
    td.remove();
  },
};

onDomReady().then(app.init);

window.unpairAllBtDevices = async () => {
  window.app.log('Getting existing permitted Bluetooth devices...');
  const devices = await navigator.bluetooth.getDevices();
  window.app.log(`> Got ${devices.length} Bluetooth devices.`);
  // These devices may not be powered on or in range, so scan for
  // advertisement packets from them before connecting.
  devices.forEach(async device => {
    try {
      window.app.log(`Disconnecting from "${device.name}"...`);
      BluetoothDeviceToUnpair(device);
    } catch (error) {
      window.app.log(`Error! ${error}`);
    }
  });
  connectedDevicesHashtable.clear();
};
window.listDevices = async selected => {
  const element = document.getElementById('devices-container');
  element.classList.remove('hidden');

  configs.room = `event.${events[selected.id].id}`;

  const select = document.getElementById('devices');
  let result = events.filter(event => event.code === selected.value);

  result = { ...result };

  devicesArr = result[0].raspberries;

  if (select.options) {
    for (let i = select.options.length - 1; i >= 0; i -= 1) {
      if (select.options[i].value !== 'static') {
        select.options[i].remove();
      }
    }
  }
  devicesArr.forEach((x, i) => {
    const opt = x.label;
    const el = document.createElement('option');
    el.setAttribute('id', i);
    el.textContent = opt;
    el.value = opt;
    select.appendChild(el);
  });
  if (!devicesArr.length) {
    const el = document.createElement('option');
    el.setAttribute('id', 0);
    el.textContent = 'No devices on this event';
    el.value = 'opt';
    select.appendChild(el);
  }
};

window.getRaspberriesSensors = async selected => {
  configs.id = selected.value;
  const rb = devicesArr.filter(device => device.label === selected.value);
  const sensorsName = rb.map(el => el.sensors.map(n => n.mac_address)).flat(1);
  window.app.initSocket(sensorsName);

  const element = document.getElementById('event-sensors');
  if (element) element.remove();

  window.app.tableCreate(sensorsName, 'event-sensors', 'red');

  const devices = await navigator.bluetooth.getDevices();
  window.app.log(`> Got ${devices.length} Bluetooth devices.`);

  // array of sensors which are already paired
  const paired = sensorsName.filter(sensor => pairedDevices.includes(sensor));
  if (!paired.length) {
    const newDevice = await navigator.bluetooth.requestDevice({
      optionalServices: [bluetooth.service],
      filters: [{ namePrefix: 'MP_J4' }],
    });
    connectToBluetoothDeviceFromAdvertisement(newDevice);
    return;
  }

  devices.forEach(async device => {
    if (!paired.includes(device.name)) {
      BluetoothDeviceToUnpair(device);
      return;
    }

    connectToBluetoothDeviceFromAdvertisement(device);
  });
};
